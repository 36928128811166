import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Img from "gatsby-image"
//import globally 
//import styles from "./HoverCardBlock.module.css"

const HoverCardBlock = props => {
  return (
    <Container className="big-y-margin">
        <Container className="d-none d-lg-block">
            <Row>
                <Col>
                
      <Row
        style={{
          height: "100%",
          alignItems: "center"
        }}
      >
        <Col className="hoverCardCol"
        style={props.style}>
          <div className="rounded-big-card hoverCardBlock" sm={4}>
            <div>{props.children}</div>
          </div>
        </Col>
        <Col>
          <div className="styles.hoverCardContainer">
            <div className="styles.hoverCardImg">
              <Img
                className="round"
                fluid={props.fluid}
                alt={props.imgAlt}
                style={{
                  width: "617px",
                  right: 0,
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
      </Col>
            </Row>
            </Container>

        <Container className="d-md-none">
            <Row>
                <Col>
                <div className="hoverCardImg">
              <Img
                className="round"
                fluid={props.fluid}
                alt={props.imgAlt}
                style={{
                  width: "100%",
                  right: 0,
                }}
              />
            </div>
            <div className={`rounded-big-card`} sm={12}>
                <div style={{fontSize: '24px', padding: '20px'}}>{props.children}</div>
            </div>
                    
                </Col>
            </Row>

        </Container>
    </Container>
  )
}

export default HoverCardBlock
